import styled from '@emotion/styled'
import { Detail } from 'app/components/Detail'
import { Features } from 'app/components/Features'
import { Hero } from 'app/components/Hero'
import { SEO } from 'app/components/SEO'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function OfferPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
      {context.headerProps ? (
        <Header
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          pageID={context.id}
          {...context.headerProps}
        />
      ) : null}
      {context.heroProps ? (
        <Hero
          {...context.heroProps}
          languagePrefix={context.languagePrefix}
          variant="small"
        />
      ) : null}
      {context.detailProps ? (
        <div className="background-light">
          <img src="/arc_top_light.svg" alt="" />
          <div className="background-light__color">
            <Detail
              {...context.detailProps}
              languagePrefix={context.languagePrefix}
            />
          </div>
        </div>
      ) : null}
      {context.featuresProps ? (
        <div className="background-dark">
          <img
            src="/arc_top_dark.svg"
            alt=""
            style={{ backgroundColor: '#F3EFEA' }}
          />
          <div className="background-dark__color">
            <Features
              {...context.featuresProps}
              languagePrefix={context.languagePrefix}
              className="dark"
            />
          </div>
        </div>
      ) : null}
      {context.footerProps ? (
        <Footer languageCode={context.languageCode} {...context.footerProps} />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
